import { ITranslation } from "./types";

export const zh: ITranslation = {
  messageFallbacks: {
    homeOrderNotReadyMsg:
      '刚刚离开商店？您的订单正在处理，请稍后查看。<br/>如果有任何问题，请联系以下邮件 <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
    errorReachOutSupportMsg:
      '如果有任何问题，请联系以下邮件 <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
  },
  api: {
    E_FORCED_LOGOUT: "您已经被强制退出登录",
  },
  orderStatus: {
    draft: "草稿",
    contested: "有争议",
    paid: "已支付",
    unpaid: "未支付",
    pending: "待处理",
    reviewed: "已审核",
    paymentProcessing: "支付处理中",
    refunded: "已退款",
    unknown: "未知",
  },
  receiptsList: {
    headings: {
      date: "Date",
      time: "Time",
      status: "Status",
      total: "Total",
    },
    action: {
      view: "View",
    },
    statusTooltip: {
      draft: "Draft - Order being processed by the system",
      paid: "Paid - Order has been processed and paid",
      paymentProcessing:
        "Payment Processing - Order has been processed and payment is under process",
      unpaid: "Unpaid - Order has been processed but payment failed",
      pending:
        "Pending - Order has been contested by shopper and is pending for review by the retailer",
      reviewed:
        "Reviewed - Order has been contested by shopper and the retailer has submitted the revision",
    },
  },
  receiptDetailScreen: {
    headerTitle: "订单详情",
    subtotal: "Subtotal",
    taxBreakdown: {
      headers: {
        rate: "Tax %",
        netto: "Netto",
        brutto: "Brutto",
        tax: "Tax",
        total: "Total:",
      },
    },
    tax: "税",
    taxCRV: "CRV",
    total: "总量",
    processTime: "处理时长",
    date: "日期",
    paymentStatus: "支付状态",
    orderId: "订单号",
    listOfProducts: "產品",
  },
  receiptDetailComplain: {
    reportProblem: "报告错误",
    submitReview: "申请审核",
    confirmTitle: "订单已发送",
    confirmButton: "完成",
    addMissingItem: "添加缺失商品",
    addMissingItemMessage: "添加备注 (选填)",
  },
  receiptsScreen: {
    label: {
      contested: "审核中",
      draft: "草稿",
      empty: "空",
      paid: "已支付",
      pending: "草稿",
      refunded: "已退款",
      reviewed: "已审核",
      unknown: "未知",
      unpaid: "未支付",
      paymentProcessing: "支付处理中",
    },
    message: {
      contested: "争议消息",
      draft: "草稿消息",
      empty: "空消息",
      paid: "已支付",
      pending: "处理中消息",
      refunded: "已退款消息",
      reviewed: "已审核消息",
      unpaid: "未支付消息",
      paymentProcessing: "支付处理中消息",
    },
    minutes: "分钟",
    hours: "小时",
    days: "天",
  },
  slideModal: {
    close: "关闭",
    searchPlaceholder: "搜索关键词",
  },
  receiptsHome: {
    receiptPortal: "收据门户",
    selectPaymentType: "选择您希望的方式来查找您的收据",
    cardBin: "卡号前 %{cardBinDigitsCount} 位数字(BIN/IIN)",
    last4: "Last four digits of your payment method",
    last4Apple: "Last four digits of your Apple Pay Number",
    last4Google: "Last four digits of your Google Pay Number",
    instructionApple:
      "Locate last four digits of your Apple Pay Card Number in your Apple Wallet",
    openWallet: "打开钱包",
    instructionGoogle:
      "Locate last four digits of your Google Pay Card Number in your Wallet",
    instructionExternalId: "在您的支付应用或银行对账单中查找支付参考号",
    externalIdLabel: "支付参考号码",
    externalIdPlaceholder: "",
    expDate: "Expiration date",
    visitDate: "Date of your visit",
    submit: "Retrieve Receipt(s)",
    registerEmail: "Enter your email to subscribe to future receipts",
    registerEmailSuccess: "Email address updated successfully.",
    registerEmailFail:
      "Email address wasn't updated because there wasn't any receipts associated with provided credit card information and/or visit date.",
    unrecognizedError: "An unrecognized error occurred",
    inconclusiveCardInfo: "We can not find your receipt",
    invalidCaptcha: "Invalid captcha, please try again",
    oasisApiError: "无法连接订单服务,请稍后重试。",
  },
};
