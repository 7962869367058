import React, { useContext, useState } from "react";
import { MasterContext } from "src/lib/masterContext";
import { ReceiptInfo } from "../ReceiptsForm/types";
import styled from "styled-components";
import { Tooltip } from "reactstrap";
import { Icon } from "src/assets/icons/icon";
import { translate, translateOrderStatus } from "src/lib/i18n";
import { useCurrencyFormatter } from "src/lib/hooks/useCurrencyFormatter";
import { Flavor } from "src/lib/flavors";

interface ReceiptsListProps {
  receipts: ReceiptInfo[];
}

const Table = styled.table`
  @media (min-width: 768px) {
    max-width: 66%;
  }
`;

const TableHeader = styled.th`
  padding: 8px;
`;

const TableCell = styled.td`
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

export const ReceiptsListTable: React.FC<ReceiptsListProps> = ({
  receipts,
}) => {
  const {
    flavor,
    appConfig: { timezone },
  } = useContext(MasterContext);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Table>
      <thead>
        <tr
          style={{
            borderTop: "1px solid #8A8A8A",
            borderBottom: "1px solid #8A8A8A",
          }}
        >
          <TableHeader>{translate("receiptsList.headings.date")}</TableHeader>
          <TableHeader>{translate("receiptsList.headings.time")}</TableHeader>
          <TableHeader id="StatusInfoTooltip">
            {translate("receiptsList.headings.status")} <Icon icon="InfoIcon" />
          </TableHeader>
          <TableHeader>{translate("receiptsList.headings.total")}</TableHeader>
          <TableHeader></TableHeader>
        </tr>
      </thead>
      <tbody>
        {receipts.map((x) => (
          <ReceiptRow
            key={x.id}
            receipt={x}
            flavor={flavor}
            timezone={timezone}
          />
        ))}
      </tbody>
      <Tooltip
        isOpen={tooltipOpen}
        target="StatusInfoTooltip"
        toggle={toggle}
        autohide={false}
        placement={"bottom"}
        style={{ textAlign: "left" }}
      >
        <ul style={{ paddingLeft: "16px", marginBottom: "4px" }}>
          <li>{translate("receiptsList.statusTooltip.draft")}</li>
          <li>{translate("receiptsList.statusTooltip.paid")}</li>
          <li>{translate("receiptsList.statusTooltip.paymentProcessing")}</li>
          <li>{translate("receiptsList.statusTooltip.unpaid")}</li>
          <li>{translate("receiptsList.statusTooltip.pending")}</li>
          <li>{translate("receiptsList.statusTooltip.reviewed")}</li>
        </ul>
      </Tooltip>
    </Table>
  );
};

function ReceiptRow(props: {
  receipt: ReceiptInfo;
  timezone: string;
  flavor: Flavor;
}) {
  const { receipt, timezone, flavor } = props;
  const { formatCurrency } = useCurrencyFormatter(receipt.currencyCode);

  return (
    <tr>
      <TableCell>
        {flavor.formatTableDateTime(receipt.draftCreatedAt, timezone).date}
      </TableCell>
      <TableCell>
        {flavor.formatTableDateTime(receipt.draftCreatedAt, timezone).time}
      </TableCell>
      <TableCell>{translateOrderStatus(receipt.status)}</TableCell>
      <TableCell>
        {receipt.status === "draft"
          ? "Pending"
          : formatCurrency(receipt.totalPrice)}
      </TableCell>
      <TableCell>
        <a
          rel="noreferrer"
          href={`/receipts/${receipt.id}/${receipt.sessionToken}`}
          target="_blank"
        >
          {translate("receiptsList.action.view")}
        </a>
      </TableCell>
    </tr>
  );
}
