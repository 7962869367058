import React, { useMemo } from "react";
import { TouchableOpacity } from "react-native";
import { Row, Col } from "reactstrap";
import card from "src/assets/images/instructions/google/card.jpeg";
import { translate } from "src/lib/i18n";
import { isMobileDevice } from "src/lib/utils";

const GOOGLE_PAY_HREF = "market://launch?id=com.google.android.apps.walletnfcrel";
export const GooglePayInstructions: React.FC = () => {

  const isMobile = useMemo(() => isMobileDevice(), []);
  const handleOpenWallet = () => {
    window.location.href = GOOGLE_PAY_HREF;
  };

  return (
    <Row className="justify-content-center mb-3">
      <Col sm={3}>
        <p className="fw-bolder">
          {translate("receiptsHome.instructionGoogle")}
        </p>
        {isMobile ? <a href={GOOGLE_PAY_HREF}>{translate("receiptsHome.openWallet")}</a> : null}
      </Col>
      <Col sm={4}>
        {isMobile ? (
          <TouchableOpacity onPress={handleOpenWallet}>
            <img style={{ width: "100%" }} src={card} alt="" />
          </TouchableOpacity>
        ) : (
          <img style={{ width: "100%" }} src={card} alt="" />
        )}
      </Col>
    </Row>
  );
};
