import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import { translate } from "./i18n";
import { isEmpty, isNil, repeat } from "ramda";

dayjs.extend(utc);
dayjs.extend(tz);

export interface IReceiptItem {
  id: string;
  orderId: string;
  name: string;
  quantity: number;
  isDraft: boolean;
  rin: string;
  image: string | null;
  totalPrice?: string;
}

export const getOrdersFromApiOrders = (
  apiOrders: { orders: ApiOrder[] } | { draftOrders: ApiOrder[] } | void
) =>
  // eslint-disable-next-line no-nested-ternary
  apiOrders
    ? "orders" in apiOrders
      ? apiOrders.orders
      : apiOrders.draftOrders
    : [];

export const getTimeSpent = (createdAt: string, processedAt: string) => {
  const createdDate = new Date(createdAt);
  const processedDate = new Date(processedAt);
  const diffMs = processedDate.getTime() - createdDate.getTime();

  const days = Math.floor(diffMs / 86400000);
  const hours = Math.floor((diffMs % 86400000) / 3600000);
  const mins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

  if (days > 0) {
    return `${days} ${translate("receiptsScreen.days")} ${hours} ${translate(
      "receiptsScreen.hours"
    )} ${mins} ${translate("receiptsScreen.minutes")}`;
  }

  if (hours > 0) {
    return `${hours} ${translate("receiptsScreen.hours")} ${mins} ${translate(
      "receiptsScreen.minutes"
    )}`;
  }
  return `${mins} ${translate("receiptsScreen.minutes")}`;
};

export const timezoneDate = (dateTime: string, timezone: string) => {
  let date = dayjs(dateTime);
  if (!isNil(timezone) && !isEmpty(timezone)) {
    date = date.tz(timezone);
  }
  return date;
};

export const formatDateTime = (dateTime: string, timezone: string) => {
  const date = timezoneDate(dateTime, timezone);
  const formatDate = "DD.MM.YYYY";
  const formatTime = "HH:mm";

  return {
    date: date.format(formatDate),
    time: date.format(formatTime),
  };
};

export const formatTaxName = (name: string, rate: string, len: number) => {
  const paddingLength = len - rate.length + 1;

  const padding = paddingLength > 0 ? repeat(" ", paddingLength).join("") : "";

  // e.g. formatTaxName('VAT', '23%') => 'VAT= 23%'
  return name + "=" + padding + rate;
};

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  return /android|iphone|ipad|ipod|windows phone|blackberry|bb10|mobile/i.test(userAgent);
}
