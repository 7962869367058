import React, {useMemo} from "react";
import { TouchableOpacity } from "react-native";
import { Col, Row } from "reactstrap";
import applePayInstruction from "src/assets/images/instructions/apple/apple_pay_instructions.png";
import { translate } from "src/lib/i18n";
import {isMobileDevice} from "../../lib/utils";

const APPLE_PAY_HREF = "shoebox://";
export const ApplePayInstruction: React.FC = () => {

  const isMobile = useMemo(() => isMobileDevice(), []);
  const handleOpenWallet = () => {
    window.location.href = APPLE_PAY_HREF;
  };

  return (
    <Row className="justify-content-center mb-3">
      <Col sm={3}>
        <p className="fw-bolder">
          {translate("receiptsHome.instructionApple")}
        </p>
        {isMobile ? <a href={APPLE_PAY_HREF}>{translate("receiptsHome.openWallet")}</a> : null}
      </Col>
      <Col sm={4}>
        {isMobile ? (
          <TouchableOpacity onPress={handleOpenWallet}>
              <img style={{ width: "100%" }} src={applePayInstruction} alt="" />
          </TouchableOpacity>
        ) : (
          <img style={{ width: "100%" }} src={applePayInstruction} alt="" />
        )}
      </Col>
    </Row>
  );
};
