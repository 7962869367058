import { translate } from "../../../lib/i18n";
import { color2 } from "../../../styles";
import { Tag } from "../../../components/Tag";
import { hexToRgba } from "../../../utils";

const createHeaderTagInfo = (
  title: string,
  tagInfo: string,
  tagColor: string,
  tagBackgroundColor: string
) => ({
  title,
  tagInfo,
  tagColor,
  tagBackgroundColor,
});

const getHeaderTagInfo = (order: ApiOrderDetail) => {
  switch (order.status) {
    case "draft":
      return createHeaderTagInfo(
        translate("receiptsScreen.label.pending") as string,
        translate("receiptsScreen.message.pending") as string,
        color2.GRAY4,
        hexToRgba(color2.GRAY4, 0.15)
      );

    case "contested":
      return createHeaderTagInfo(
        translate("receiptsScreen.label.contested") as string,
        translate("receiptsScreen.message.contested") as string,
        color2.BLUE_AIFI,
        hexToRgba(color2.BLUE_AIFI, 0.15)
      );

    case "empty":
      return createHeaderTagInfo(
        translate("receiptsScreen.label.empty") as string,
        translate("receiptsScreen.message.empty") as string,
        color2.GRAY4,
        hexToRgba(color2.GRAY4, 0.15)
      );

    case "unpaid":
      return createHeaderTagInfo(
        translate("receiptsScreen.label.unpaid") as string,
        translate("receiptsScreen.message.unpaid") as string,
        color2.RED,
        hexToRgba(color2.RED, 0.15)
      );

    case "reviewed":
      return createHeaderTagInfo(
        translate("receiptsScreen.label.reviewed") as string,
        translate("receiptsScreen.message.reviewed") as string,
        color2.GREEN,
        hexToRgba(color2.GREEN, 0.15)
      );

    case "paymentProcessing":
      return createHeaderTagInfo(
        translate("receiptsScreen.label.paymentProcessing") as string,
        translate("receiptsScreen.message.paymentProcessing") as string,
        color2.GRAY4,
        hexToRgba(color2.GRAY4, 0.15)
      );

    default:
      return null;
  }
};

export const buildHeaderTag = (order: ApiOrderDetail) => {
  const headerTagInformation = getHeaderTagInfo(order);
  if (headerTagInformation) {
    return (
      <Tag
        title={headerTagInformation.title}
        tagInfo={headerTagInformation && headerTagInformation.tagInfo}
        tagColor={headerTagInformation.tagColor}
        tagBackgroundColor={headerTagInformation.tagBackgroundColor}
      />
    );
  }
  return null;
};

export const buildHeaderValue = (order: ApiOrderDetail): null | string => {
  switch (order.status) {
    case "paid":
      return translate("receiptsScreen.label.paid") as string;
    case "empty":
      return translate("receiptsScreen.label.empty") as string;
    default:
      return null;
  }
};
